body,html{
    margin: 0;
    padding: 0;
    background: rgba(212, 251, 223, 0.96);
    font-family: 'Open Sans', sans-serif;
  }
  
  .form{
    background: rgba(255, 255, 255);
    height: 450px;
    width: 350px;
    margin: 40px auto;
    text-align: center;
    border-radius: 10px;
  }
  
  h1{
    margin: 0;
  }
  
  .login_box{
    padding: 20px 20px;
  }
  
  .login_box input{
    width: 100%;
    margin: 5px auto;
    box-sizing: border-box;
    border: 1px solid;
    border-radius: 2px;
    padding: 8px 5px;
  }
  
  p{
    font-size: 13px;
    padding: 5px 0;
  }
  
  .sign_in{
    width: 100%;
    background: rgba(110, 188, 133, 0.96);
    border: 0;
    border-radius: 2px;
    padding: 8px 0;
    font-size: 15px;
    box-shadow: 1px 1px 3px 0;
  }
  
  .sign_in:hover{
    background: black;
    color: rgba(110, 188, 133, 0.96);
    transition: 0.3s ease;
  }
  
  button{
    width: 40%;
    display: inline;
    background: rgba(255, 255, 255);
    border: 0.1px solid;
    border-radius: 3px;
    padding: 5px 5px;
    font-weight: bold;
  }
  
  button:hover{
    color: rgba(110, 188, 133, 0.96);
    transition: 0.3s ease;
  }
  
  span{
    font-weight: bold;
  }
  
  span:hover{
    text-decoration: underline;
  }